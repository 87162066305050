import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import Bates = require("Everlaw/Bates");
import Binder = require("Everlaw/Binder");
import Code = require("Everlaw/Code");
import Cmp = require("Everlaw/Core/Cmp");
import Dataset = require("Everlaw/Processing/ProcessingDataset");
import DocumentMutator = require("Everlaw/DocumentMutator");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Freeform = require("Everlaw/Freeform");
import Highlight = require("Everlaw/Review/Highlight");
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import Language = require("Everlaw/Language");
import Metadata = require("Everlaw/Metadata");
import { LlmExtractionInfo } from "Everlaw/LlmExtractionsGridCell";
import { Note, NoteUtil } from "Everlaw/Note";
import Perm = require("Everlaw/PermissionStrings");
import Preference = require("Everlaw/Preference");
import ProcessingDefs = require("Everlaw/Processing/ProcessingDefs");
import Production = require("Everlaw/Production/Production");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rating = require("Everlaw/Rating");
import RatingConflict = require("Everlaw/RatingConflict");
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import UI = require("Everlaw/UI");
import Upload = require("Everlaw/Upload");
import User = require("Everlaw/User");
import { AlternateFormat, RedactionStampSummary } from "Everlaw/Document";
import DocType = require("Everlaw/DocType");
import { ImageRedaction } from "Everlaw/ImageRedaction";
import { DocRotation } from "Everlaw/DocRotation";
import { MetadataRedaction } from "Everlaw/MetadataRedaction";
import { OverviewTopicInfo } from "Everlaw/OverviewTopicGridCell";
import { Redaction } from "Everlaw/Redaction";
import ChatRedaction from "Everlaw/Review/ChatViewer/Redaction/ChatRedaction";
import { MediaRedaction } from "Everlaw/Review/MediaRedaction";
import { FsiSpreadsheetRedaction, SpreadsheetRedaction } from "Everlaw/Review/SpreadsheetRedaction";
import { FpiRedaction } from "Everlaw/FpiRedaction";
import { AddressListType } from "Everlaw/Type";
import Promotion = require("Everlaw/PromotionReason");
import * as ActionNode from "Everlaw/UI/ActionNode";
import MalwareWarningDialog from "Everlaw/MalwareWarningDialog";
import { Flag } from "Everlaw/Processing/ProcessingDefs";
import { CodeSuggestion, CodeSuggestionJson } from "Everlaw/GridColumns";
import { IconButton } from "Everlaw/UI/Button";

const maxDownloadPages = 100;
const maxPrintPreviewPages = 1000;

interface DocProcessingStatus {
    EXAMINE?: Document.StageStatus;
    PDF?: Document.StageStatus;
    TEXT?: Document.StageStatus;
    lastError?: string;
}

const docTypeAbbreviations: { [type: string]: string } = {
    Other: "Other",
    Document: "Doc",
    Spreadsheet: "Sheet",
    Presentation: "Pres",
    Email: "Email",
    Mailbox: "Mailbox",
    PDF: "PDF",
    Audio: "Audio",
    Video: "Video",
    Text: "Text",
    Binary: "Binary",
    Calendar: "Cal",
    HTML: "HTML",
    Image: "Image",
    Compressed: "Archive",
    CAD: "CAD",
    Database: "DB",
    Chat: "Chat",
    SupportTicket: "Support Ticket",
    ProjectManagement: "PM",
    Transcript: "Transcript",
    GIS: "GIS",
    Empty: "Empty",
};

class Document extends Base.Object {
    get className() {
        return "Document";
    }
    // JSON data
    override id: Document.Id;
    primaryUploadId: number;
    type: string;
    // the prefix and begin bates number, along with the database, uniquely identify the document
    batesPrefix: string;
    batesNumber: Bates.Number;
    // the full begin bates as a string
    beginBates: string;
    // the full end bates as a string
    endBates: string;
    canTransferBates: boolean;
    numPages: number;
    /**
     * If there are fewer image versions than pages, then the image version for each page is
     * imageVersions[0].
     */
    private imageVersions: number[];
    nativeVersion: number;
    pdfVersion: number;
    // Do we expect this document to have a pdf (vs. images)?
    hasPdf: boolean;
    // Similar to the above, but this actually checks the PDF version as well to see if it indicates
    // a pdf file actually exists.
    hasPdfWithVersion: boolean;
    // Checks the image version to see if the document has an image
    hasImageWithVersion: boolean;
    textSize: number;
    billableSize: number;
    languages: DocLanguage[];
    codes: Code.Id[];
    promotions: Promotion.Id[];
    binders: Binder.Id[];
    viewedBy: User.Id[];
    rating: Rating;
    adminRating: Rating;
    ratingConflict: RatingConflict;
    metadata: { [fieldId: string]: Metadata.Value };
    freeformCode: { [codeId: string]: Freeform.Value };
    predictions: { [modelId: string]: number };
    holdoutId: number;
    notesPreview: string;
    noteTextPreview: string;
    numAttyNotes: number;
    numTextNotes: number;
    nativeFilename: string;
    notesLoaded = false;
    processingFlags: number;
    processingStatus: DocProcessingStatus;
    attachmentGroup: string;
    familyDateValue: Metadata.Value;
    versionGroup: string;
    unitGroup: number;
    // Conversion stuff
    productionId: number; // The document was created from this production.
    datasetId: number;
    nativePath: string;
    attachmentGroupSize: number;
    firstView: boolean; // for documents loaded by review/document.rest only
    // the productions in which this document has been produced in
    originalProductions: number[]; // for documents loaded by review/document.rest only
    producedTo: string[]; // Document displays that have been produced from this document.
    // An array containing signed S3 urls for page images, 0-indexed. This array is lazily populated
    // so particular elements may be null if they haven't been loaded yet.
    pageUrls: ({ url: string; expiration: number } | Document.NULL_URL)[] = [];
    nativeExtractedMetadata: { [name: string]: string } = {};
    // a list of all formats associated with this document, including the default ones
    alternateFormats: AlternateFormat[] = [];
    // decrypted native format for this doc, if one exists. Used for search page downloads
    decryptedNative: AlternateFormat = null;
    // hold notices related to this document
    holdNoticeIds: number[] = [];
    llmDescription: string;
    llmCompletionId: number;
    overviewTopics: OverviewTopicInfo[];
    codingSuggestions: CodeSuggestionJson[];
    llmExtractions: LlmExtractionInfo;
    /**
     * redactionStampSummary could be null when either "Redaction Stamp Details" or "Redaction
     * Stamps" is not present in the result table
     */
    redactionStampSummary: RedactionStampSummary = null;
    activeChronId: number | null;

    // Non-JSON parameters
    // Map of Semantic/Conflict field id to associated Original field.
    private _interpToOrigMetadata: { [interpretedFieldId: number]: Metadata.Field[] } = null;
    private _origValueByName: { [name: string]: string } = null;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override display() {
        return this.beginBates;
    }
    getSystemFieldValue(name: Metadata.System) {
        const fld = Metadata.getSystemField(name);
        return fld && fld.docValue(this);
    }
    getSystemFieldDisplayValue(name: Metadata.System) {
        const value = this.getSystemFieldValue(name);
        return value && value.displayValue();
    }
    getNativeExtractedMetadataFields() {
        return Object.keys(this.nativeExtractedMetadata).map(
            (k) => new Metadata.RawField({ id: k }),
        );
    }
    override compare(y: Document) {
        return (
            Cmp.strCI(this.batesPrefix, y.batesPrefix) || this.batesNumber.compare(y.batesNumber)
        );
    }
    processedByEverlaw() {
        return Is.number(this.datasetId);
    }
    hasErrorForStage(stage: ProcessingDefs.Stage) {
        return (
            this.processingStatus
            && stage.id in this.processingStatus
            && (<any>this.processingStatus)[stage.id].success === false
        );
    }
    getNameList(): string[] {
        const nameList: string[] = [];
        const filenameField = Metadata.fieldByName(Metadata.Canonical.FILENAME);
        const origFilenameField = Metadata.fieldByName(Metadata.Canonical.ORIGINAL_FILENAME);
        const filename = filenameField && filenameField.docValue(this);
        const origFilename = origFilenameField && origFilenameField.docValue(this);

        if (filename) {
            nameList.push(filename.displayValue());
        } else if (origFilename) {
            nameList.push(origFilename.displayValue());
        } else if (this.nativeFilename) {
            nameList.push(this.nativeFilename);
        }

        const titleValue = this.getSystemFieldValue(Metadata.System.Title);
        if (titleValue) {
            nameList.push(titleValue.displayValue());
        }
        nameList.push(this.display());
        return nameList;
    }
    getOtherBates() {
        const otherBates = Metadata.fieldByName(Metadata.Canonical.OTHER_BATES);
        return otherBates ? otherBates.docValue(this) : null;
    }
    /*
     * Returns document icon. Alt text will default to the type abbreviation if not supplied.
     *
     * Hopefully one day all Icons could be of any size and color. Until then, pixelSize and
     * darkness let you choose the 30px dark icon, the 24px dark icon, or the 24px light icon.
     */
    getIcon(params: Icon.Params = {}, darkness: "dark" | "white" = "dark"): Icon {
        let name = DocType.toIcon[this.type] || "file-other";
        // Dark icons don't use "dark" in the name so only need to check for white
        if (darkness === "white") {
            name += "-white";
        }
        return new Icon(name, Object.assign({ alt: `${this.getTypeAbbreviation()} file`, params }));
    }
    getNativeIcon() {
        const iconName = this.getNativeIconName();
        if (!iconName) {
            return null;
        }

        const canDownload =
            this.hasNative(true)
            && User.me.can(Perm.DOWNLOAD, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
        const icon = new IconButton({
            iconClass: iconName,
            disabled: !canDownload,
            tooltip: this.type + (canDownload ? "; click to download native" : ""),
        });

        if (!canDownload) {
            return icon.node;
        }

        return new ActionNode(icon.node, {
            onClick: () => this.safeNativeDownload(this.decryptedNative || undefined),
            makeFocusable: true,
        }).node;
    }
    getNativeIconName() {
        return this.type ? DocType.toIcon[this.type] : null;
    }
    getTypeAbbreviation() {
        return docTypeAbbreviations[this.type] || "Other";
    }
    imageVersion(page: number) {
        if (this.imageVersions.length < this.numPages) {
            return this.imageVersions[0];
        }
        return this.imageVersions[page];
    }
    /**
     * Returns the slice of image versions on the range [from, to) (same semantics as Array.slice).
     */
    imageVersionSlice(from: number, to: number) {
        if (this.imageVersions.length < this.numPages) {
            return Arr.repeat(this.imageVersions[0], to - from);
        }
        return this.imageVersions.slice(from, to);
    }

    // Do not use this link in an href, the user may be downloading a malicious file.
    // Use safeNativeDownload() instead
    private unsafeNativeDownloadLink(format?: AlternateFormat) {
        const baseUrl = `native/download.do?id=${this.id}`;
        if (format) {
            return Project.CURRENT.url(`${baseUrl}&formatId=${format.id}`);
        }
        return Project.CURRENT.url(baseUrl);
    }

    /**
     * Check for malware before initiating download
     */
    safeNativeDownload(format?: AlternateFormat): void {
        if (this.hasFlag(Flag.FlaggedMalicious)) {
            new MalwareWarningDialog(
                Project.CURRENT,
                {
                    results: [{ id: this.id, beginBates: this.beginBates }],
                    count: 1,
                },
                () => UI.initiateDownload(this.unsafeNativeDownloadLink(format)),
            );
        } else {
            UI.initiateDownload(this.unsafeNativeDownloadLink(format));
        }
    }

    getNotes(attyOnly?: boolean) {
        return Base.get(Note).filter(
            (n) =>
                n.docId === this.id && (!attyOnly || n.parentType === NoteUtil.ParentType.Document),
        );
    }
    getImageRedactions(): ImageRedaction[] {
        return Base.get(ImageRedaction).filter((r) => r.docId === this.id);
    }
    getFpiRedactions(): FpiRedaction[] {
        return Base.get(FpiRedaction).filter((r) => r.docId === this.id);
    }
    getMetadataRedactions(): MetadataRedaction[] {
        return Base.get(MetadataRedaction).filter((r) => r.docId === this.id);
    }
    getSpreadsheetRedactions(): SpreadsheetRedaction[] {
        return Base.get(SpreadsheetRedaction).filter((r) => r.docId === this.id);
    }
    getFsiSpreadsheetRedactions(): FsiSpreadsheetRedaction[] {
        return Base.get(FsiSpreadsheetRedaction).filter((r) => r.docId === this.id);
    }
    getMediaRedactions(): MediaRedaction[] {
        return Base.get(MediaRedaction).filter((r) => r.docId === this.id);
    }
    getChatRedactions(): ChatRedaction[] {
        return Base.get(ChatRedaction).filter((r) => r.docId === this.id);
    }
    getHighlights(): Highlight[] {
        return Base.get(Highlight).filter((r) => r.docId === this.id);
    }
    getDocRotations(): DocRotation[] {
        return Base.get(DocRotation).filter((r) => r.documentId === this.id);
    }
    getDocumentSet() {
        if (this.datasetId) {
            return Base.get(Dataset, this.datasetId);
        }
        if (this.primaryUploadId) {
            return Base.get(Upload.Homepage, this.primaryUploadId);
        }
        if (this.productionId) {
            return Base.get(Production, this.productionId);
        }
    }
    /**
     * Initiate a PDF download for this document.  If it has > maxDownloadPages, do an export
     * instead.
     * @returns true if an export was run, false if it was a direct download
     */
    private unsafeDownloadOrExportPDF(
        options: Preference.PDFOptions,
        print?: boolean,
        getPreview = false,
    ): HTMLIFrameElement {
        if (this.numPages > maxDownloadPages) {
            Task.createTask(
                "tasks/exportOnePDF.rest",
                Object.assign(options, {
                    docId: this.id,
                    name: this.display(),
                    singlePDF: true, // This should never be a zip file, regardless of the options.
                }),
            );
            Dialog.ok("Export started", [
                Dom.div(
                    `This document is too large to ${
                        print ? "print" : "download"
                    } from the review window.`,
                ),
                Dom.div(
                    "A PDF export has been started instead - visit ",
                    Dom.a({ href: "home.do", tabindex: "-1" }, "Project Home"),
                    ` to ${print ? "print" : "download"} the result when it's finished.`,
                ),
            ]);
        }
        if (this.numPages > maxPrintPreviewPages) {
            return null;
        }
        if (options.inline) {
            return Document.initiatePrint(Object.assign({ id: this.id }, options), getPreview);
        } else {
            UI.initiateDownload("getPDF.do", Object.assign({ id: this.id }, options), "POST");
        }
    }

    /**
     * Check for malware before initiating download
     */
    safeDownloadOrExportPDF(
        options: Preference.PDFOptions,
        print?: boolean,
        getPreview = false,
    ): HTMLIFrameElement {
        if (this.hasFlag(Flag.FlaggedMalicious)) {
            new MalwareWarningDialog(
                Project.CURRENT,
                {
                    results: [{ id: this.id, beginBates: this.beginBates }],
                    count: 1,
                },
                () => this.unsafeDownloadOrExportPDF(options, print),
            );
        } else {
            return this.unsafeDownloadOrExportPDF(options, print, getPreview);
        }
    }

    private _setupFreeformCodes(values?: { [codeId: number]: { value: unknown } }) {
        this.freeformCode = {};
        if (values) {
            Object.entries(values).forEach(([codeId, value]) => {
                const code = Base.get(Freeform.Code, codeId);
                this.freeformCode[codeId] = new Freeform.Value(code, value.value);
            });
        }
    }
    private _setupMetadata(metadata: any[]) {
        const fullMd: { [fieldId: string]: Metadata.Value } = {};
        metadata.forEach((m) => {
            const field = Base.get(Metadata.Field, m.fieldId);
            if (field) {
                fullMd[field.id] = new Metadata.Value(
                    field,
                    m.value,
                    m.originalSource,
                    m.interpretedFieldId,
                    !!m.containsEditedValue,
                );
            }
        });
        this.metadata = fullMd;
    }
    private _setFamilyDateValue(val: any) {
        this.familyDateValue = new Metadata.Value(Metadata.getFamilyDateField(), val);
    }
    private _setupLanguages(languages: { [code: string]: number }) {
        this.languages = [];
        Object.entries(languages).forEach(([code, percent]) => {
            this.languages.push(new DocLanguage(Base.get(Language, code), percent));
        });
        Arr.sort(this.languages, {
            cmp: function (a, b) {
                return b.percent - a.percent;
            },
        });
    }

    override _mixin(params: any) {
        Object.assign(this, params);
        if (params.modificationType == Document.ModificationType.NOTES_MODIFICATION) {
            this.updateNotesWithModification(params);
            return;
        } else if (params.modificationType == Document.ModificationType.HIGHLIGHTS_MODIFICATION) {
            this.updateNoteParentWithModification(Highlight, params);
            return;
        } else if (
            params.modificationType == Document.ModificationType.IMAGE_REDACTIONS_MODIFICATION
        ) {
            this.updateNoteParentWithModification(ImageRedaction, params);
            return;
        } else if (
            params.modificationType == Document.ModificationType.FPI_REDACTIONS_MODIFICATION
        ) {
            this.updateNoteParentWithModification(FpiRedaction, params);
            return;
        } else if (
            params.modificationType == Document.ModificationType.SPREADSHEET_REDACTIONS_MODIFICATION
        ) {
            return;
        } else if (
            params.modificationType
            === Document.ModificationType.FSI_SPREADSHEET_REDACTIONS_MODIFICATION
        ) {
            return;
        } else if (
            params.modificationType == Document.ModificationType.METADATA_REDACTIONS_MODIFICATION
        ) {
            this.updateMetadataRedactionsWithModification(params);
            return;
        } else if (
            params.modificationType === Document.ModificationType.MEDIA_REDACTIONS_MODIFICATION
        ) {
            this.updateNoteParentWithModification(MediaRedaction, params);
            return;
        } else if (
            params.modificationType === Document.ModificationType.CHAT_REDACTIONS_MODIFICATION
        ) {
            this.updateNoteParentWithModification(ChatRedaction, params);
            return;
        }

        if (!params.rating) {
            throw new Error("params.rating can't be null");
        }
        this.rating = Rating.byString(params.rating);
        this.adminRating = params.adminRating ? Rating.byString(params.adminRating) : null;
        this.ratingConflict = params.ratingConflict
            ? new RatingConflict(params.ratingConflict)
            : null;
        this.batesNumber = Bates.Number.fromString(params.batesNumber);
        if (params.metadata) {
            this._setupMetadata(params.metadata);
        }
        if (params.familyDateValue) {
            this._setFamilyDateValue(params.familyDateValue);
        }
        if (params.languages) {
            this._setupLanguages(params.languages);
        }
        if (params.notes) {
            this.notesLoaded = true;
            this.updateAnnotations(Note, params.notes);
        }
        if (params.highlights) {
            this.updateAnnotations(Highlight, params.highlights);
        }
        if (params.imageRedactions) {
            this.updateAnnotations(ImageRedaction, params.imageRedactions);
        }
        if (params.metadataRedactions) {
            this.updateAnnotations(MetadataRedaction, params.metadataRedactions);
        }
        if (params.spreadsheetRedactions) {
            Base.set(SpreadsheetRedaction, params.spreadsheetRedactions);
        }
        if (params.fsiSpreadsheetRedactions) {
            Base.set(FsiSpreadsheetRedaction, params.fsiSpreadsheetRedactions);
        }
        if (params.fpiRedactions) {
            this.updateAnnotations(FpiRedaction, params.fpiRedactions);
        }
        if (params.mediaRedactions) {
            this.updateAnnotations(MediaRedaction, params.mediaRedactions);
        }
        if (params.chatRedactions) {
            this.updateAnnotations(ChatRedaction, params.chatRedactions);
        }
        if (params.freeformCode) {
            this._setupFreeformCodes(params.freeformCode);
        }
    }

    // This function is used to update Highlights and Image Redactions
    private updateNoteParentWithModification(baseClass: any, params: any) {
        if (params.removed) {
            Base.remove(Base.get(baseClass, params.removed));
        }
        if (params.added) {
            //add new notes, if any
            Base.set(baseClass, params.added);
        }
    }

    private updateNotesWithModification(params: any) {
        if (params.removed) {
            const notes = Base.get(Note, params.removed);
            Base.remove(notes);
            Arr.wrap(notes).forEach((note: Note) => {
                if (note.parentType !== NoteUtil.ParentType.Document) {
                    this.removeNoteFromParent(note);
                }
            });
        }
        if (params.added) {
            //add new notes, if any
            const notes = Base.set(Note, <Note[]>params.added);
            notes.forEach((note: Note) => {
                if (note.parentType !== NoteUtil.ParentType.Document) {
                    this.addNoteToParent(note);
                }
            });
        }
    }

    private updateMetadataRedactionsWithModification(params: any) {
        if (params.removed) {
            const metadataRedactions = Base.get(MetadataRedaction, params.removed);
            Base.remove(metadataRedactions);
        }
        if (params.added) {
            //add new notes, if any
            Base.set(MetadataRedaction, <MetadataRedaction[]>params.added);
        }
    }

    private getNoteParentClass(note: Note) {
        switch (note.parentType) {
            case NoteUtil.ParentType.MetadataRedaction:
                return MetadataRedaction;
            case NoteUtil.ParentType.ImageRedaction:
                return ImageRedaction;
            case NoteUtil.ParentType.SpreadsheetRedaction:
                return SpreadsheetRedaction;
            case NoteUtil.ParentType.FsiSpreadsheetRedaction:
                return FsiSpreadsheetRedaction;
            case NoteUtil.ParentType.FpiRedaction:
                return FpiRedaction;
            case NoteUtil.ParentType.MediaRedaction:
                return MediaRedaction;
            case NoteUtil.ParentType.Highlight:
                return Highlight;
            default:
                throw Error("Unexpected parent type: " + note.parentType);
        }
    }

    private addNoteToParent(note: Note) {
        const baseClass = this.getNoteParentClass(note);
        const parent: Highlight | Redaction = Base.globalStore<Highlight | Redaction>(
            baseClass,
        ).get(note.parentId);
        if (!!parent) {
            parent.addNote(note);
            // Media redactions allow notes on creation, so we don't want them to update edit information.
            // Every other type requires you to edit the redaction to add a note.
            parent instanceof Redaction
                && !(parent instanceof MediaRedaction)
                && parent.updateEditor(User.me.id);
            Base.publish(parent);
        }
    }

    private removeNoteFromParent(note: Note) {
        const baseClass = this.getNoteParentClass(note);
        const parent: Highlight | Redaction = Base.globalStore<Highlight | Redaction>(
            baseClass,
        ).get(note.parentId);
        if (!!parent) {
            parent.removeNote(note);
            parent instanceof Redaction && parent.updateEditor(User.me.id);
            Base.publish(parent);
        }
    }

    private classIsAnnotation(baseClass: any) {
        return (
            baseClass === Note
            || baseClass === Highlight
            || baseClass === ImageRedaction
            || baseClass === FpiRedaction
            || baseClass === MetadataRedaction
            || baseClass === MediaRedaction
            || baseClass === ChatRedaction
        );
    }

    private updateAnnotations(baseClass: any, annotations: any[]) {
        // FIRST remove all removed annotations, and THEN add new ones, so updates behave well
        // naturally.
        if (!this.classIsAnnotation(baseClass)) {
            throw new Error(
                "Attempted to use updateAnnotation function to update non-annotation objects",
            );
        }
        const allIds: { [id: number]: boolean } = {};
        annotations.forEach((a) => (allIds[a.id] = true));
        Base.remove(
            Base.get(baseClass).filter((a) => {
                const docId = (<Note | Highlight | Redaction>a).docId;
                return docId === this.id && !allIds[a.id];
            }),
        );
        //add new notes, if any
        Base.set(baseClass, annotations);
    }

    hasFlag(flag: ProcessingDefs.Flag | Production.Flag) {
        return !!(this.processingFlags & flag.bitMask);
    }
    allFlags() {
        return [...Base.get(ProcessingDefs.Flag), ...Base.get(Production.Flag)].filter(
            this.hasFlag,
            this,
        );
    }

    updateProjectMetadataValue(
        field: Metadata.Field,
        value: any,
        callback: CommitCallback,
        publish = true,
    ) {
        // We're investigating why complex types sometimes are serialized as [Object object]
        // See https://podio.com/easyesicom/feature-roadmap/apps/bugs/items/11423
        // TODO: remove this logging when that issue is solved
        if (field.type instanceof AddressListType && value === "[Object object]") {
            import("Everlaw/Bugsnag").then((bugsnag) => {
                bugsnag.notify(Error(`User saved value of [Object object] for field: ${field.id}`));
            });
        }
        // Construct the new value now (so we can use Metadata.Value.equals - it just checks the value
        // and the field, but we might as well use it)
        const newVal = new Metadata.Value(field, value);
        if (!this.metadata[field.id] || !this.metadata[field.id].equals(newVal)) {
            this.metadata[field.id] = newVal;
        } else {
            // Same value.
            return;
        }
        publish
            && this.commit(
                new DocumentMutator().updateProjectMetadataValue(field, value),
                callback,
            );
    }
    removeProjectMetadataValue(field: Metadata.Field, callback: CommitCallback, publish = true) {
        if (field.id in this.metadata) {
            delete this.metadata[field.id];
            publish
                && this.commit(new DocumentMutator().removeProjectMetadataValue(field), callback);
        }
    }
    updateFreeformCodeValue(
        code: Freeform.Code,
        value: any,
        callback?: CommitCallback,
        publish = true,
    ) {
        const newVal = new Freeform.Value(code, value);
        if (!this.freeformCode[code.id] || !this.freeformCode[code.id].equals(newVal)) {
            this.freeformCode[code.id] = newVal;
            publish
                && this.commit(
                    new DocumentMutator().updateFreeformCodeValue(code, value),
                    callback,
                );
        }
    }
    removeFreeformCodeValue(code: Freeform.Code, callback?: CommitCallback, publish = true) {
        if (code.id in this.freeformCode) {
            delete this.freeformCode[code.id];
            publish && this.commit(new DocumentMutator().removeFreeformCodeValue(code), callback);
        }
    }
    getFreeformCodeValue(codeId: number): Freeform.Value {
        if (!Is.defined(this.freeformCode)) {
            this._setupFreeformCodes();
        }
        return this.freeformCode[codeId];
    }

    private getInterpToOrigMetadata() {
        if (!this._interpToOrigMetadata) {
            const interpToOrig: { [interpId: number]: Metadata.Field[] } = {};
            if (this.metadata) {
                Object.values(this.metadata).forEach((v) => {
                    if (v.getField().isOriginal() && v.interpretedField) {
                        let origFields = interpToOrig[v.interpretedField.id];
                        if (!origFields) {
                            origFields = [];
                            interpToOrig[v.interpretedField.id] = origFields;
                        }
                        origFields.push(v.getField());
                    }
                });
            }
            this._interpToOrigMetadata = interpToOrig;
        }
        return this._interpToOrigMetadata;
    }

    origValueByName(name: string): string {
        if (this._origValueByName) {
            return this._origValueByName[name];
        }
        this._origValueByName = {};
        Object.keys(this.metadata).forEach((id) => {
            const v = this.metadata[id];
            if (!v.field.isOriginal()) {
                return;
            }
            this._origValueByName[v.field.name] = v.value;
        });
        return this._origValueByName[name];
    }

    /**
     * Returns an array of the Original fields mapped to the given field on this doc.
     */
    getOriginalCounterparts(field: Metadata.Field) {
        // Fields that are not Semantic, Canonical, or Conflict will always get an empty array.
        return this.getInterpToOrigMetadata()[field.id] || [];
    }
    commit(mutator: DocumentMutator, callback: CommitCallback, error?: Rest.Callback) {
        if (mutator.isEmpty()) {
            callback();
        } else {
            Rest.post("documents/mutate.rest", {
                docId: this.id,
                mutations: mutator.toJSON(),
            }).then(
                (data) => {
                    // data is [document, [list of events from mutator], [mutator Json]]
                    const docData = data[0];
                    this._mixin(docData);
                    Base.publish(this);
                    callback && callback(docData, data[1], data[2].mutations);
                },
                (e) => {
                    error && error(e);
                    throw e;
                },
            );
        }
    }
    _remove() {
        Base.remove(this);
        Base.remove(this.getNotes());
        Base.remove(this.getHighlights());
        this.removeRedactions();
    }
    private removeRedactions() {
        Base.remove(this.getImageRedactions());
        Base.remove(this.getSpreadsheetRedactions());
        Base.remove(this.getFsiSpreadsheetRedactions());
        Base.remove(this.getMetadataRedactions());
        Base.remove(this.getMediaRedactions());
    }
    // Does the document have a native? Normally we hide the native for processed containers, but
    // you can pass in the optional flag to disable that behavior if the user is a superuser, org admin
    // or uploader (since usually the native does in fact exist in s3).
    hasNative(includeContainers = false) {
        return (
            !!this.nativeFilename
            && (!this.hasFlag(ProcessingDefs.Flag.IsContainer)
                || (includeContainers
                    && User.canUploadToCurrentProject(User.Override.ELEVATED_OR_ORGADMIN)))
        );
    }
    preview(params?: import("Everlaw/Review/Preview").PreviewParams) {
        if (params && params.chronDoc) {
            this.activeChronId = params.chronDoc.chronologyId;
        } else if (params && params.chronDocHighlight) {
            this.activeChronId = params.chronDocHighlight.chronologyId;
        }
        // Lazily load Preview module since it takes a while with all its dependencies (~100ms)
        import("Everlaw/Review/Preview").then(({ Preview }) => {
            Preview(this, params);
        });
    }

    getLlmDescription(): string {
        return this.llmDescription;
    }

    setLlmDescription(s): void {
        this.llmDescription = s;
    }

    getLlmCompletionId(): number {
        return this.llmCompletionId;
    }

    getCodeIdsForSuggestions(): number[] {
        if (Is.defined(this.codingSuggestions)) {
            return Object.keys(this.codingSuggestions).map(Number);
        } else {
            return [];
        }
    }

    getCodesForSuggestions(): CodeSuggestion[] {
        if (Is.defined(this.codingSuggestions)) {
            const suggestedCodes: CodeSuggestion[] = [];
            this.codingSuggestions.forEach((suggestion: any) => {
                const codeObj = Base.get(Code, suggestion.codeId);
                if (codeObj) {
                    const codeSuggestion = Object.assign(codeObj, {
                        outdated: suggestion.outdated,
                        suggestion: suggestion.suggestion,
                    });
                    suggestedCodes.push(codeSuggestion as CodeSuggestion);
                }
            });
            return suggestedCodes;
        } else {
            return [];
        }
    }
}

interface CommitCallback {
    (docData?: any, events?: any[], mutator?: any): void;
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Document {
    export type Id = number & Base.Id<"Document">;

    export type NULL_URL = "NULL_URL";

    // Handler for update push notifications.  If the document doesn't already exist, don't create it!
    export function updateHandler(data: any) {
        if (Base.get(Document, data.document.id)) {
            Base.set(Document, data.document);
        }
    }
    export const similar = 11;

    let deletedId = 0;
    export class Deleted {
        id = "DELETED" + deletedId++;
    }

    export interface StageStatus {
        success: boolean;
        timestamp: number;
        host: string;
        codeVersion: string;
    }

    // This needs to match the ModificationType enum in WebReviewDocumentService.java
    export enum ModificationType {
        NOTES_MODIFICATION = "NOTES_MODIFICATION",
        HIGHLIGHTS_MODIFICATION = "HIGHLIGHTS_MODIFICATION",
        IMAGE_REDACTIONS_MODIFICATION = "IMAGE_REDACTIONS_MODIFICATION",
        FPI_REDACTIONS_MODIFICATION = "FPI_REDACTIONS_MODIFICATION",
        SPREADSHEET_REDACTIONS_MODIFICATION = "SPREADSHEET_REDACTIONS_MODIFICATION",
        FSI_SPREADSHEET_REDACTIONS_MODIFICATION = "FSI_SPREADSHEET_REDACTIONS_MODIFICATION",
        METADATA_REDACTIONS_MODIFICATION = "METADATA_REDACTIONS_MODIFICATION",
        MEDIA_REDACTIONS_MODIFICATION = "MEDIA_REDACTIONS_MODIFICATION",
        CHAT_REDACTIONS_MODIFICATION = "CHAT_REDACTIONS_MODIFICATION",
    }

    let iframe: HTMLIFrameElement;
    /* Previously, printing from the review window was done using initiateDownload to embed a PDF that
     * uses Javascript to automatically print, without any other user interaction. However, browsers
     * have updated to disallow embedded PDFs from automatically calling print (see for example
     * https://pdfium.googlesource.com/pdfium.git/+/2021804f1b414c97667c03d7ab19daf66f6a19ef
     * ). So instead, we open a dialog to get a gesture from which to call print()
     */
    export function initiatePrint(content?: any, getPreview?: boolean) {
        if (iframe) {
            Dom.destroy(iframe);
        }
        const params = Object.keys(content)
            .filter(
                (key) =>
                    // null, undefined, and empty array should not be in the parameters.
                    content[key] != null && content[key].length !== 0,
            )
            .map((key) =>
                Arr.wrap(content[key])
                    .map((val) => key + "=" + val)
                    .join("&"),
            )
            .join("&");
        iframe = Dom.create("iframe", {
            src: "getPDF.do?" + params,
            width: "600px",
            height: "400px",
        });
        if (getPreview) {
            return iframe;
        }
        const dialogContent = Dom.div();
        Dom.place(iframe, dialogContent);
        const dialog = QueryDialog.create({
            title: "Print Preview",
            prompt: dialogContent,
            submitText: "Print",
            submitIsSafe: true,
            onSubmit: () => {
                try {
                    iframe.contentWindow.focus();
                    iframe.contentWindow.print();
                } catch (e) {
                    Dialog.ok(
                        "Error printing document",
                        "There was an error printing this document."
                            + " This usually occurs because your browser's PDF viewer can't display"
                            + " the document. Please ensure that your PDF viewer is enabled, or"
                            + " download this document as a PDF then print it from a PDF viewer.",
                    );
                }
                // Ideally, we would also close the dialog here. However, in testing, immediately
                // closing the dialog caused the print preview to also close. The next solution would be
                // to use the onafterprint listener
                // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onafterprint
                // to close the dialog after the user has chosen to either print or cancel.
                // However, that event didn't seem to fire correctly. For now, we just have to live
                // with this suboptimal behavior. Improving it would be great!
            },
            cancelText: "Close",
            onCancel: () => {
                dialog.hide();
            },
            destroyOnClose: true,
        });
    }

    enum BaseFormat {
        Native = "Native",
        Image = "Image",
        Pdf = "Pdf",
        Text = "Text",
    }

    // This needs to match the Format enum in AlternateFormat.java
    enum Format {
        CHAT_JSON = "CHAT_JSON",
        DECRYPTED_NATIVE = "DECRYPTED_NATIVE",
        DEFAULT_NATIVE = "DEFAULT_NATIVE",
        DEFAULT_IMAGE = "DEFAULT_IMAGE",
        DEFAULT_PDF = "DEFAULT_PDF",
        DEFAULT_TEXT = "DEFAULT_TEXT",
        SPREADSHEET = "SPREADSHEET",
        TRANSCRIPTION = "TRANSCRIPTION",
        TRANSCODED = "TRANSCODED",
        TRANSCODED_THUMBNAIL = "TRANSCODED_THUMBNAIL",
    }

    // Formats that we allow a user to download from the review window.
    export enum DownloadableFormats {
        DECRYPTED_NATIVE = "DECRYPTED_NATIVE",
        DEFAULT_NATIVE = "DEFAULT_NATIVE",
        DEFAULT_IMAGE = "DEFAULT_IMAGE",
        DEFAULT_PDF = "DEFAULT_PDF",
        DEFAULT_TEXT = "DEFAULT_TEXT",
        SPREADSHEET = "SPREADSHEET",
        TRANSCRIPTION = "TRANSCRIPTION",
        TRANSCODED = "TRANSCODED",
        TRANSCODED_THUMBNAIL = "TRANSCODED_THUMBNAIL",
    }

    interface FormatMetadata {
        encrypted: boolean;
        language: string;
    }

    export interface AlternateFormat {
        id: number;
        documentId: Document.Id;
        format: Format;
        baseFormat: BaseFormat;
        metadata: FormatMetadata;
        version: number;
        s3Key: string;
        size: number;
        imageVersions: number[];
        default: boolean;
    }

    export interface RedactionStampSummary {
        stampDetails: RedactionStampDetail[];
        latestStamp: RedactionStampDetail;
    }

    export interface RedactionStampDetail {
        stampName: string;
        detail: string;
        abbreviation: string;
    }
}

class DocLanguage {
    constructor(
        public language: Language,
        public percent: number,
    ) {}
    display() {
        return this.language.displayName + " - " + this.percent.toString() + "%";
    }
    getColor() {
        return this.language.getColor();
    }
    compare(other: DocLanguage) {
        return this.language.compare(other.language);
    }
}

export = Document;
