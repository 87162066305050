import Base = require("Everlaw/Base");
import Document = require("Everlaw/Document");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import Project = require("Everlaw/Project");
import Property = require("Everlaw/Property");
import Rest = require("Everlaw/Rest");
import ResultsTableView = require("Everlaw/ResultsTableView");
import SearchFilter = require("Everlaw/SearchFilter");
import UserObject = require("Everlaw/UserObject");
import Util = require("Everlaw/Util");
import array = require("dojo/_base/array");

// Note that since this calls an endpoint that ignores the user, it should only be used for Prior
// Search terms, where the search may not belong to the current user. Otherwise you should prefer
// to use one of the endpoints that checks the search cache first.
const getSearchWithEqlIgnoreUser = (id: number): Promise<SearchResult> => {
    return Rest.get("search/getSearchIgnoreUser.rest", { id: id }).then((searchJson) =>
        Base.set(SearchResult, JSON.parse(searchJson)),
    );
};

class SearchResult extends UserObject implements SearchResult.MaybePartial {
    get className() {
        return "SearchResult";
    }
    override id: SearchResult.Id;
    timestamp: number;
    lastUsed: number;
    name: string;
    hasCustomName: boolean; // true if the user has set a name for the search
    eql: string; // can be null on some pages if intentionally excluded in SearchResult#toJsonTree
    position: number[];
    numResults: number; // the number of matching documents (excluding context-only docs)
    numGroups: number;
    currDocId: Document.Id;
    projectId: Project.Id;
    groupBy: DocumentGroupType.Id; // can be null
    isGrouped: boolean;
    sort: string;
    viewId: number;
    defaultViewId: number;
    userVisible: boolean;
    ownerId: number;
    paramsId: number;
    isTagSearch: boolean;
    isAssignmentSearch: boolean;
    uploadId: number; // 0 indicates that this is not an upload search
    includesDuplicates: boolean;
    searchFilter: SearchFilter;
    searchFilterId: number;
    filteredSearchNumResults: number;
    numUnviewedVersions?: number;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        // Some properties can change to null, but Gson doesn't include null properties. So we
        // need to explicitly set them to null before mixing in parameters.
        this.groupBy = null;
        this.viewId = null;
        this.searchFilterId = null;
        Object.assign(this, params);
    }
    /**
     * For SearchResult update the data returned is the SearchResult JSON rather than just the
     * UserObject JSON as in the superclass (UserObject) method this overrides.
     */
    override onFavorite(data, callback: () => void) {
        Base.set(SearchResult, data);
        callback && callback();
    }
    /**
     * Display the name of the search and the name of the filter applied to that search, if it exists.
     */
    override display() {
        let searchName = this.displayWithoutFilters();
        if (this.searchFilter) {
            searchName += ` filtered by: ${this.displayFilters(!this.name)}`;
        }
        return searchName;
    }

    /**
     * Display only the name of the search, without the filter applied to it.
     */
    displayWithoutFilters(): string {
        return this.name;
    }
    displayFilters(alwaysUseEql = false): string {
        if (this.searchFilter) {
            const searchFilterName = this.searchFilter.name || this.searchFilter.eql;
            return alwaysUseEql ? this.searchFilter.eql : searchFilterName;
        } else {
            return "";
        }
    }
    // By default, we sort by most recently used
    override compare(other: SearchResult) {
        return other.lastUsed - this.lastUsed || other.id - this.id;
    }
    rename(newname: string): Promise<string> {
        return Rest.post("search/rename.rest", {
            id: this.id,
            name: newname,
        }).then((data) => {
            this._mixin(data);
            Base.publish(this);
            return this.name;
        });
    }
    refresh(): Promise<null> {
        return Rest.post("search/refresh.rest", { id: this.id }).then((data) => {
            this._mixin(data);
            Base.publish(this);
            return null;
        });
    }
    displayPosition() {
        return (
            array
                .map(this.position, (n) => {
                    return Util.num(n + 1);
                })
                .join(".")
            + " of "
            + Util.num(this.numGroups)
        );
    }
    displayNumGroups() {
        return Util.num(this.numGroups);
    }
    displayTotalDocuments() {
        return Util.num(this.numResults) + " total";
    }
    getSearchForPriorSearch() {
        return this.eql ? Promise.resolve(this) : getSearchWithEqlIgnoreUser(this.id);
    }
}

module SearchResult {
    export type Id = number & Base.Id<"SearchResult">;

    /**
     * Something that might be either a SearchResult or a PartialSearch. If you need any
     * SearchResult data that a PartialSearch doesn't have, you'll need to use getSearchForPriorSearch.
     */
    export interface MaybePartial {
        id: SearchResult.Id;
        display: () => string;
        getSearchForPriorSearch: () => Promise<SearchResult>;
    }

    /**
     * This class contains a subset of the functionality and data of SearchResult. See
     * PriorSearchSelect for more information.
     */
    export class PartialSearch implements SearchResult.MaybePartial {
        id: SearchResult.Id;
        name: string;
        constructor(ps: Property.PriorSearchArgs) {
            this.id = <SearchResult.Id>ps.searchId;
            this.name = ps.searchName;
        }
        display() {
            return this.name;
        }
        getSearchForPriorSearch() {
            const sr = Base.get(SearchResult, this.id);
            return sr && sr.eql ? Promise.resolve(sr) : getSearchWithEqlIgnoreUser(this.id);
        }
    }

    /**
     * Many search endpoints return a larger JSON object that may contain view and filter information
     */
    export interface RestResponse {
        search: SearchResult;
        resultsTableView?: ResultsTableView;
        defaultResultsTableView?: ResultsTableView;
        baseSearch?: SearchResult.RestResponse;
    }
}

export = SearchResult;
