/* eslint-disable camelcase */
import Dom = require("Everlaw/Dom");
import dojo_cookie = require("dojo/cookie");
import dojo_on = require("dojo/on");
import Input = require("Everlaw/Input");
import LocalStorage = require("Everlaw/LocalStorage");
import Preference = require("Everlaw/Preference");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import SbFree = require("Everlaw/Context/SbFree");
import Util = require("Everlaw/Util");
import UrlHash = require("Everlaw/UrlHash");
import User = require("Everlaw/User");
import Server = require("Everlaw/Server");

const LocalStorageKey = "inWalkMeFlow";
const PLAYER_ID = "walkme-player-local";
const TEST_URL =
    "https://cdn.walkme.com/users/b4fa4acf06a7438088f8d6822cdce77c/test/walkme_b4fa4acf06a7438088f8d6822cdce77c_https.js";
const PRODUCTION_URL =
    "https://cdn.walkme.com/users/b4fa4acf06a7438088f8d6822cdce77c/walkme_b4fa4acf06a7438088f8d6822cdce77c_https.js";

/**
 * Describes some of the methods exposed by the WalkMe API. See
 * https://developer.walkme.com/reference/overview for more options and add to this interface if
 * you need to expose more methods.
 */
interface WalkMeAPI {
    startFlowById: (walkthroughId: number) => void;
}

declare const window: Window & {
    WalkMePlayerAPI: any;
    walkme_player_event: any;
    walkme_event: any;
    walkme_report_analytics: boolean;
    WalkMeAPI: WalkMeAPI;
};

export function getWindow() {
    return window;
}

export function walkMePermitted() {
    return !Server.isFedRamp();
}

const walkMePages = [
    "search.do",
    "home.do",
    "data.do",
    "settings.do",
    "review.do",
    "analytics.do",
    "chron.do",
    "clustering.do",
];
const walkMeExclusions = ["search.do#view=datavis", "search.do#view=search"];
// set fixed value for each walkme tutorial triggered by toast.
// name has to be the same with each recommendation's name
export enum TutorialId {
    STORY_LABELS_EVENTS = 614288,
    CREATE_PRODUCTION_PROTOCOL = 1014739,
    PREDICTIVE_CODING = 513162,
    HOMEPAGE = 1217870,
    ASSIGNMENT_GROUPS = 104004,
    STORYBUILDER = 613946,
    DATA_VISUALIZER = 431209,
    // Tutorial IDs below haven't been set yet
    SEARCH_TERM_REPORTS = 1881845,
    TRANSFER_WORK_PRODUCT = 1886123,
    PROJECT_ANALYTICS = 1886126,
    EXHIBIT_LISTS = 1886127,
    TESTIMONY = 1886131,
}

function walkmePlayer() {
    return document.getElementById(PLAYER_ID);
}

function showMenu() {
    if (!window.walkme_player_event) {
        window.walkme_player_event = function (eventData) {
            if (eventData.Type == "AfterMenuClose") {
                Dom.show(walkmePlayer());
            }
        };
    }
    if (!window.WalkMePlayerAPI.isMenuOpen()) {
        window.WalkMePlayerAPI.toggleMenu();
    }
    Dom.hide(walkmePlayer());
}

function loaded(displayMenu: boolean) {
    if (!window.walkme_event) {
        window.walkme_event = function (eventData) {
            //Slightly unclear how reliable these events are, but probably the best we can do
            if (eventData.Type == "FlowStarted") {
                LocalStorage.setItem(LocalStorageKey, dojo_cookie("XSRF-TOKEN"));
            }
            if (eventData.Type == "FlowCompleted" || eventData.Type == "FlowStopped") {
                LocalStorage.removeItem(LocalStorageKey);
            }
        };
    }
    if (displayMenu) {
        showMenu();
    }
}

function isLoaded() {
    return window.WalkMePlayerAPI != null;
}

function forceHide() {
    if (walkmePlayer() != null) {
        Dom.hide(walkmePlayer());
    }
    delete window.walkme_player_event;
    if (isLoaded() && window.WalkMePlayerAPI.isMenuOpen()) {
        window.WalkMePlayerAPI.toggleMenu();
    }
}
export function turnOff() {
    Preference.GENERAL.walkMeShown.setUserDefault(false);
    forceHide();
}
function hideWalkMe(e: Event) {
    LocalStorage.removeItem(LocalStorageKey);
    Dom.hide(walkmePlayer());
    QueryDialog.create({
        title: "Hiding walkthrough",
        prompt: Dom.div(
            { style: { margin: "8px 8px 0px 16px" } },
            "Do you want to hide the walkthrough menu? You can access it again through\
                the help dialog in the top right corner of your screen.",
        ),
        onSubmit: () => {
            turnOff();
            return true;
        },
        onCancel: () => {
            Dom.show(walkmePlayer());
            return true;
        },
        promptType: "div",
        destroyOnClose: true,
        submitText: "Yes",
    });
    e.stopPropagation();
}

let walkmeAdded = false;

export function loadWalkMe(displayMenu = true) {
    if (isLoaded()) {
        loaded(displayMenu);
        return;
    }
    if (walkmeAdded) {
        return;
    }
    walkmeAdded = true;

    const walkme = document.createElement("script");
    walkme.type = "text/javascript";
    walkme.async = true;
    walkme.src = Server.isLive() ? PRODUCTION_URL : TEST_URL;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(walkme, s);
    window["_walkmeConfig"] = { smartLoad: true };
    if (isLoaded()) {
        loaded(displayMenu);
    } else {
        Object.defineProperty(window, "WalkMePlayerAPI", {
            configurable: true,
            enumerable: true,
            get: function () {
                return this.walkme;
            },
            set: function (val) {
                this.walkme = val;
                loaded(displayMenu);
            },
        });
    }
}

/*
 *  Three possible states:
 *   1. Has not yet been added to the DOM, and walkme script isn't loaded yet
 *   2. Has already been added to the DOM, but walkme script isn't loaded yet
 *   3. Already in DOM and script is loaded, but bubble is hidden
 *
 *  Five possible outcomes
 *   1. Add to DOM, load script, display menu
 *   2. Load script, display menu
 *   3. Show the menu
 *   4. Show the bubble
 *   5. Construct the bubble, then show it
 */
export function show(displayMenu = false) {
    if (!walkMePermitted()) {
        return;
    }
    Preference.GENERAL.walkMeShown.setUserDefault(true);
    if (!isLoaded() && displayMenu) {
        if (walkmePlayer() == null) {
            constructWalkMe();
        }
        loadWalkMe();
    } else if (displayMenu) {
        showMenu();
    } else {
        constructWalkMe();
    }
}

export function isEnabled() {
    return Preference.GENERAL.walkMeShown.get();
}

export function showWalkMeOnPage() {
    if (!JSP_PARAMS.Server.walkMeEnabled || !walkMePermitted()) {
        return false;
    }
    const p = walkMePages.filter((i) => location.pathname.indexOf(i) >= 0);
    const e = walkMeExclusions.filter((i) => window.location.href.indexOf(i) >= 0);
    return p.length > 0 && e.length == 0;
}

function shouldLoadSbFreeWalkMe() {
    return SbFree.inContext() && location.pathname.indexOf("chron.do") >= 0;
}

function constructWalkMe() {
    if (!walkMePermitted()) {
        return;
    }
    if (shouldLoadSbFreeWalkMe()) {
        loadWalkMe(false);
    }
    //Don't show menu on review window
    if (!Util.onReviewPage() && showWalkMeOnPage()) {
        if (walkmePlayer() != null) {
            Dom.show(walkmePlayer());
            return;
        }
        let closeButton;
        const playerDiv = Dom.div(
            {
                id: PLAYER_ID,
                role: "button",
                "aria-label": "Walk Me Through",
            },
            Dom.div(
                {
                    class: "walkme-out-wrapper-local",
                },
                Dom.div(
                    {
                        class: "walkme-in-wrapper-local",
                    },
                    Dom.div(
                        {
                            class: "walkme-title-local",
                        },
                        "Walk Me Through",
                    ),
                    Dom.div({
                        class: "walkme-arrow-local",
                    }),
                    Dom.div({
                        class: "walkme-bar-local",
                    }),
                ),
            ),
            (closeButton = Dom.div({
                id: "walkme-close-button",
                class: "walkme-close",
                title: "Close",
                role: "button",
            })),
        );
        Dom.addContent(document.body, playerDiv);
        dojo_on(playerDiv, Input.tap, () => loadWalkMe());
        dojo_on(closeButton, Input.tap, hideWalkMe);
    }
    //If local storage session says to load, load
    const val = LocalStorage.getItem(LocalStorageKey);
    if (val != null) {
        if (val === dojo_cookie("XSRF-TOKEN")) {
            loadWalkMe(false);
        } else {
            //Cleanup
            LocalStorage.removeItem(LocalStorageKey);
        }
    }
}

export function init() {
    if (!walkMePermitted()) {
        return;
    }

    if (!User.me || !User.me.id || !Preference.GENERAL.walkMeShown.isLoaded()) {
        return;
    }
    /*
     * WalkMe reads this window variable to decide whether or not to collect user's
     * analytics information. We want to filter out Everlaw users. Note that this is not a
     * built in WalkMe feature, we had to specifically request it so there is no documentation of
     * this feature outside of a support ticket.
     */
    window.walkme_report_analytics = !User.me.inTheEverlawOrg();
    if (Preference.GENERAL.walkMeShown.get()) {
        UrlHash.subscribe(() => {
            if (!Preference.GENERAL.walkMeShown.get()) {
                return;
            }
            if (!showWalkMeOnPage()) {
                forceHide();
            } else {
                show();
            }
        });
        constructWalkMe();
    }
}
