import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { Note, NoteUtil } from "Everlaw/Note";
import Is = require("Everlaw/Core/Is");
import Rest = require("Everlaw/Rest");
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import Base = require("Everlaw/Base");
import Document = require("Everlaw/Document");
import Geom = require("Everlaw/Geom");
import User = require("Everlaw/User");
import HighlightColor = require("Everlaw/Review/HighlightColor");
import { ReviewRectangles, SingleLocationImageAnnotation } from "Everlaw/Review/Highlighting";

class Highlight extends Base.SecuredObject implements SingleLocationImageAnnotation {
    get className() {
        return "Highlight";
    }
    docId: Document.Id;
    user: User = null;
    created: number = null;
    updated: number = null;
    text: string = null;
    color: HighlightColor.Color;
    protected _userId: User.Id = null;
    revRect: ReviewRectangles;
    notes: Note[] = [];
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        if (Is.defined(params.notes)) {
            const paramNotes: any[] = params.notes;
            for (const i in paramNotes) {
                if (!(paramNotes[i] instanceof Note)) {
                    paramNotes[i] = new Note(paramNotes[i]);
                }
            }
        }
        if (params.user) {
            this._userId = params.user;
            this.user = Base.get(User, this._userId);
        }

        let newRectangles;
        if (Is.defined(params.rectangles)) {
            newRectangles = new ReviewRectangles(
                this,
                params.rectangles.rectangles,
                params.rectangles.markedText,
                params.rectangles.pageNum,
            );
        }
        if (
            newRectangles
            && this.revRect
            && this.revRect.locationHash() !== newRectangles.locationHash()
        ) {
            Base.remove(this);
        }
        if (newRectangles) {
            this.revRect = newRectangles;
        }
        Object.assign(this, params);
        if (Is.defined(this.id)) {
            Base.add(this);
        }
    }

    getRedactionStamp() {
        return null;
    }

    getReviewRectangles() {
        return this.revRect;
    }

    getType() {
        return NoteUtil.ParentType.Highlight;
    }

    getId() {
        return this.id;
    }

    getNotes() {
        return this.notes;
    }

    getScrollLocation() {
        return this.revRect.rectangles[0];
    }

    getLocationHash() {
        return this.revRect.locationHash().concat(`, ${this.docId}`);
    }

    getDocId() {
        return this.docId;
    }

    getPageNum() {
        return this.revRect.pageNum;
    }

    getText(stripNewlines = false) {
        let text = this.revRect.markedText;
        if (stripNewlines && text) {
            text = text.replaceAll(new RegExp("\\n", "g"), " ");
        }
        return text;
    }

    getColor() {
        return this.color;
    }

    setColor(highlightColor: HighlightColor.Color) {
        this.color = highlightColor;
    }
    /**
     * Commit changes to this highlight.
     * NOTE: If you are updating this highlight's rectangles (i.e. you're changing
     * where it is positioned on the page), do so by passing the updatedRects parameter, NOT by changing
     * the rectangles directly! In several places we index ReviewRectangles by their rectangles; special
     * handling is necessary in order for those to be correctly updated.
     */
    commit(updatedRects?: Geom.Rectangle[], stamp?: RedactionStamp) {
        const rectangles = updatedRects
            ? new ReviewRectangles(
                  this,
                  updatedRects,
                  this.revRect.markedText,
                  this.revRect.pageNum,
              )
            : this.revRect;
        return Rest.post("documents/saveHighlight.rest", {
            // Always has:
            docId: this.docId,
            // If new, doesn't have:
            highlightId: this.id,
            color: this.color,
            rectangles: JSON.stringify(rectangles.stringifiableObject()),
            notes: JSON.stringify(this.notes),
        }).then((data) => {
            // TODO: We add/publish the highlight here, but saveHighlight.rest publishes the updated
            // document, and that also contains our highlight. Document._mixin then calls Base.set
            // with the JSON of our highlight as well. This doesn't actually cause problems since the
            // document stores these highlights by their ID, it just causes lots of extra publish
            // events. Since we use `add`, we ensure that `this` is actually the global note,
            // even in the event of a race condition where we finish after the mux notification.
            // Since the document uses Base.set, it will only update these fields. In either
            // case, anyone with a reference to this continues to be valid.
            //
            // If we want to remove this logic and rely on the mux notification, then we'll have
            // to be sure that none of the callers hold onto this highlight object. We can't remove
            // the publishing logic from the notification side, because then other users won't
            // learn about our new note.
            if (!Is.number(this.id)) {
                this.id = data.id;
                Base.add(this);
            }
            this._mixin(data);
            Base.publish(this);
        });
    }
    @elevatedRoleConfirm("removing a highlight")
    remove(callback?: (h: Highlight, msg?: string) => void, handleError?: Rest.Callback) {
        Base.remove(this.notes);
        if (Is.number(this.id)) {
            Rest.post("documents/deleteHighlight.rest", { highlightId: this.id }).then(
                (message) => {
                    Base.remove(this);
                    callback && callback(this, message);
                },
                (e) => {
                    if (handleError) {
                        handleError(e);
                    } else {
                        throw e;
                    }
                },
            );
        } else {
            // This wasn't a saved highlight - we don't have to do any deleting, but we should call the
            // callback!
            callback && callback(this);
        }
    }
    committed() {
        return Is.number(this.id);
    }

    // Add the given note to the highlight
    addNote(n: Note): void {
        n.parentType = NoteUtil.ParentType.Highlight;
        n.parentId = this.id;
        this.notes.push(n);
    }

    // Remove the note from the highlight, returning whether or not the note was in this.notes
    removeNote(n: Note): boolean {
        const prevLength = this.notes.length;
        this.notes = this.notes.filter((e) => e.id !== n.id);
        return this.notes.length != prevLength;
    }

    // How many notes with text are associated with this note?
    numTextNotes(): number {
        return this.notes.length;
    }
}

export = Highlight;
